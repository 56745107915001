import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions} from '../../actions';
import logo from '../../img/logo1.png';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });

    }
    handleSubmit(event) {
        event.preventDefault();
        const { email, password} = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.login(email, password));
    }

    render() {
        const { email, password } = this.state;
        return (
            <div className="gray-bg">
                <div className="middle-box text-center loginscreen animated fadeInDown">
                    <div>
                    	<div><img src={logo} className="logo1" alt="logo"/></div>
                        <div>

                            <h1 className="logo-name">RIDE PUL</h1>

                        </div>
                        <h3>Welcome to RIDE PUL</h3>


                        <form className="form_login" onSubmit={this.handleSubmit}>
                            {this.props.alert &&   <div className="error-mess">Wrong name or password</div>}

                            <div className="form-group">
                                <input type="email" name="email"  className="form-control" placeholder="Username" required="" value={email} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <input type="password" name="password" className="form-control" placeholder="Password" required="" value={password} onChange={this.handleChange}/>
                            </div>
                            <button type="submit" className="btn btn-primary block full-width m-b">Login</button>

                        </form>
                    </div>
                </div>


            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        alert: state.alert.message
    };
}

const connectedLogin = connect(mapStateToProps)(Login);
export { connectedLogin as Login };