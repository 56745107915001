import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  modalActions } from '../../actions/index';
import {getInfo} from '../../actions';
import $ from "jquery";
import Pagination from "react-js-pagination";
import { Table } from 'reactstrap';

class Rides extends Component {

    constructor(props) {
        super(props);
        this.state={
            tableData: {
                searchValue: '',
                sortValue:'',
                sortPosition: '',
                activePage: 1
            },
            pageRangeDisplayed: 5

        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.SortClass = this.SortClass.bind(this);
        this.onBtnClick = this.onBtnClick.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleChangeSelect = this.handleChangeSelect.bind(this)
    }
    handleChange(event) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                searchValue: event.target.value,
                activePage: 1
            }
        }, function () {
            const _this=this
            setTimeout(function () {
                dispatch(getInfo.GetRidesTable(_this.state.tableData))
            }, 500)
        });
    }
    handlePageChange(pageNumber) {
        $('table tr').removeClass('edited')
        const { dispatch } = this.props;
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: pageNumber
            }
        }, function () {
            dispatch(getInfo.GetRidesTable(this.state.tableData))
        });

    }
    SortClass(event){
        $('.clickable').removeClass('active_up');
        $('.clickable').removeClass('active_down');

    };

    handleSort(event){
        $('table tr').removeClass('edited')
        let element = event.target.classList;
        if(!element.contains('active_up') && !element.contains('active_down')){
            this.SortClass();
            element.add('active_up');

        }
        else if(element.contains('active_up') && !element.contains('active_down')){
            element.remove('active_up')
            element.add('active_down')
        }
        else if(!element.contains('active_up') && element.contains('active_down')){
            element.remove('active_down')
            element.add('active_up')
        }
        const { dispatch } = this.props;
        const x = this.state.tableData.sortPosition
        const { tableData } = this.state;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.currentTarget.getAttribute('data-val'),
                sortPosition: x ===''|| x ==='down'?'up':'down'
            }
        }, function () {
            dispatch(getInfo.GetRidesTable(this.state.tableData))
        });
    }
    componentDidMount() {
        if($(window).width()<=768){
            this.setState({
                pageRangeDisplayed:3
            })
        }
        const { dispatch } = this.props;
        dispatch(getInfo.GetRidesTable(this.state.tableData))
    }

// && tableData.sortValue == this.value
    onBtnClick(event, data){
        const { dispatch } = this.props;
        $('table tr').removeClass('edited')
        $(event.target).parent().parent().addClass('edited')
        return dispatch(modalActions.open_modal(event.target.getAttribute('data_modal'), data))
    }
    handleChangeSelect(event) {
        const { tableData } = this.state;
        const { dispatch } = this.props;
        this.setState({
            tableData: {
                ...tableData,
                activePage: 1,
                sortValue: event.target.value,
                sortPosition: 'up'
            }
        }, function () {
            dispatch(getInfo.GetRidesTable(this.state.tableData))
        });
    }
    render() {
        const {RidesTable, Count} =this.props
        return (
            <div className="main_container">
                <label className="search">
                    <input type="text" value={this.state.tableData.searchValue} onChange={this.handleChange} placeholder="Search" className="form-control"/>
                </label>
                <select value={this.state.tableData.sortValue} onChange={this.handleChangeSelect} className="selectBox">
                    <option value="">Change sort by</option>
                    <option value="group">Group</option>
                    <option value="rideType">Type</option>
                    <option value="rideDate">Date</option>
                    <option value="gas">Gas Money</option>
                    <option value="status">Status</option>
                </select>

                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="group">Group</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="rideType">Type</th>
                        <th  nowrap='true' >Start</th>
                        <th  nowrap='true' >End</th>
                        <th  nowrap='true' >Start Radius</th>
                        <th  nowrap='true' >End Radius</th>
                        <th  className="clickable" nowrap='true' onClick={this.handleSort} data-val="rideDate">Date</th>
                        <th  nowrap='true' >Passenger</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="gas">Gas Money</th>
                        <th className="clickable" nowrap='true' onClick={this.handleSort} data-val="status">Status</th>
                        <th >Action</th>
                    </tr>
                    </thead>
                        <tbody>{
                            RidesTable ?  RidesTable.map( (item, index)=>

                           <tr key={index}>
                                <td style={{width:'3%'}}  nowrap='true' ><div className='table_head_txt'>Number:</div> {(this.state.tableData.activePage - 1)*10 + 1 + index } </td>
                                <td style={{width:'7%'}}  nowrap='true' className="pict"><div className='table_head_txt'>Group:</div>{item.group &&  item.group.groupType && item.group.groupType.icon ? <img src={"http://www.ifixgroup.com:4050/icons/" + item.group.groupType.icon}   alt=""/> :''} {item.group &&  item.group.name? item.group.name:'No name'}</td>
                                <td style={{width:'10%'}} nowrap='true' ><div className='table_head_txt'>Type:</div>{item.rideType ? item.rideType:''}</td>
                                <td style={{width:'15%', maxWidth:'200px', minWidth: '150px'}} ><div className='table_head_txt'>Start:</div>{item.startDist && item.startDist.address ? item.startDist.address:''}</td>
                                <td style={{width:'15%', maxWidth:'200px', minWidth: '150px'}} ><div className='table_head_txt'>End:</div>{item.endDist && item.endDist.address ? item.endDist.address:''}</td>
                                <td style={{width:'5%'}} nowrap='true' data-head="Start Radius:"><div className='table_head_txt'>Start Radius:</div>{item.startDist && item.startDist.radius ? item.startDist.radius + ' mi':''}</td>
                                <td style={{width:'5%'}} nowrap='true' ><div className='table_head_txt'>End Radius:</div>{item.endDist && item.endDist.radius ? item.endDist.radius + ' mi':''}</td>
                                <td style={{width:'10%'}} nowrap='true'><div className='table_head_txt'>Date:</div>{item.rideDate ? item.rideDate:''} {item.rideTime ? item.rideTime:''}</td>
                                <td style={{width:'12%', maxWidth:'150px'}}><div className='table_head_txt'>Passenger:</div>{item.riders && item.riders.length ? item.riders.map((item1, index1)=> <span key={index1}>{item1.name}{index1!==(item.riders.length-1)?', ':''}</span>):'No Passenger'} </td>
                                <td style={{width:'8%'}}  nowrap='true' ><div className='table_head_txt'>Gas Money:</div>{item.gas ? item.gas + '$':'0$'}</td>
                                <td style={{width:'10%'}} nowrap='true' ><div className='table_head_txt'>Status:</div>{item.status ? item.status:''}</td>


                                <td style={{width:'10%'}} nowrap='true' className="actions" >
                                    <div className='table_head_txt'>Action:</div>
                                    {item.status === "open"?<span className="delete fa"  onClick={(e) => { this.onBtnClick(e, item ) }}  data_modal="DeleteRide" ></span>:''}
                                </td>
                            </tr>
                        )
                            :false
                        }</tbody>

                </Table>
                <div className="pagin_cont">

                    {
                        Count && Count>10?
                        <Pagination
                            activePage={this.state.tableData.activePage}
                            itemsCountPerPage={10}
                            totalItemsCount={Count ? Count:''}
                            pageRangeDisplayed={this.state.pageRangeDisplayed}
                            firstPageText={<i className='f_link1'>First</i>}
                            lastPageText={<i className='b_link1'>Last</i>}
                            prevPageText={<i className='f_link2'/>}
                            nextPageText={<i className='b_link2'/>}
                            onChange={this.handlePageChange}
                        />
                        :''
                    }
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
     const {RidesTable, Count} = state.RidesTable
    return {
        RidesTable,
        Count
    };
}

const connectedRides = connect(mapStateToProps)(Rides);
export { connectedRides as Rides };