import React from 'react';
import { Router, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../../helpers';
import { PrivateRoute, PrivateRoute2 } from '../PrivateRoute';
import { alertActions } from '../../actions';
import {Login}  from '../Login';
import DashboardFix  from '../fixed.dash.js';
import {Dashboard}  from '../Dashboard';
import {Users}  from '../Users';
import {Groups}  from '../Groups';
import {Rides}  from '../Rides';



class RouteComponent extends React.Component {
    constructor(props) {
        super(props);
        let LoginStatus = JSON.parse(localStorage.getItem('LoginStatus') );
        let user_token = JSON.parse(localStorage.getItem('user_token') )
        if((!LoginStatus && user_token) || (LoginStatus && !user_token)){
            localStorage.removeItem('user_token');
            localStorage.setItem('LoginStatus', false);
            history.push('/');
        }
        history.listen((location, action) => {
            if((!LoginStatus && user_token) || (LoginStatus && !user_token)){
                localStorage.removeItem('user_token');
                localStorage.setItem('LoginStatus', false);
                history.push('/');
            }
            window.scrollTo(0, 0)
            const { dispatch } = this.props;
            dispatch(alertActions.clear());

        });

    }


    render() {
        let LoginStatus = JSON.parse( localStorage.getItem('LoginStatus'))
        return (
            <div>

                <Router history={history} >
                    <div>
                        {LoginStatus ?<DashboardFix/>:"" }

                        <Switch>
                            <PrivateRoute2 exact path="/" component={Login}/>
                            {/*<PrivateRoute exact path="/dashboard" component={Home} />*/}
                            {/*<Route component={Nomatch}/>*/}
                            <PrivateRoute path="/dashboard" exact component={Dashboard} />
                            <PrivateRoute path="/users" exact component={Users} />
                            <PrivateRoute path="/groups" exact component={Groups} />
                            <PrivateRoute path="/rides" exact component={Rides} />
                        </Switch>

                    </div>
                </Router>

            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert
    };
}

const connectedRouteComponent = connect(mapStateToProps)(RouteComponent);
export { connectedRouteComponent as RouteComponent };