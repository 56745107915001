import { userService } from '../services';
import { alertActions } from './';
import { modalActions } from './';
import { history } from '../helpers';
export const userActions = {
    login,
    logout,
    EditUser,
    DeleteUser,
    EditGroup,
    DeleteGroup,
    ViewUser,
    ViewGroup,
    DeleteRide,
    MessageGroup,
    GetGraph,
    GetStatisic,
    GetSettings,
    SetSettings,
};
function login(username, password) {
    return dispatch => {
        dispatch(request());
        userService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                    history.push('/dashboard');
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request() { return { type: 'LOGIN_REQUEST' } }
    function success(user) { return { type: 'LOGIN-SUCCESS', user } }
    function failure(error) { return { type: 'LOGIN-FAILURE', error } }
}
function logout() {
    localStorage.removeItem('user_token');
    localStorage.setItem('LoginStatus', false);
    history.push('/');
    return { type: 'LOGOUT' };
}
function EditUser(user) {
    return dispatch => {
        dispatch(request(user));

        userService.EditUser(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(success1());
                    dispatch(alertActions.success('Edit successful'));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(user) { return { type: 'SUCCESS_EDIT_USER', user } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function EditGroup(group) {
    return dispatch => {
        dispatch(request(group));

        userService.EditGroup(group)
            .then(
                group => {
                    dispatch(success(group));
                    dispatch(success1());
                    dispatch(alertActions.success('Edit successful'));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(group) { return { type: 'SUCCESS_EDIT_GROUP', group } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function DeleteUser(user) {
    return dispatch => {
        dispatch(request(user));
        userService.DeleteUser(user)
            .then(
                user => {
                    dispatch(success(user));
                    dispatch(success1());
                    dispatch(alertActions.success('Delete successful'));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure());
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                    dispatch(alertActions.alert_error(error));
                    dispatch(alertActions.clear())
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(user) { return { type: 'SUCCESS_DELETE_USER', user } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function DeleteGroup(id) {
    return dispatch => {
        dispatch(request());
        userService.DeleteGroup(id)
            .then(
                group => {
                    dispatch(success(group));
                    dispatch(success1());
                    dispatch(alertActions.success('Delete successful'));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure());
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                    dispatch(alertActions.alert_error(error));
                    dispatch(alertActions.clear())
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(group) { return { type: 'SUCCESS_DELETE_GROUP', group } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function DeleteRide(ride_del) {
    return dispatch => {
        dispatch(request());
        userService.DeleteRide(ride_del)
            .then(
                ride => {
                    dispatch(success(ride));
                    dispatch(success1());
                    dispatch(alertActions.success('Delete successful'));
                    dispatch(modalActions.close_modal());
                },
                error => {
                    dispatch(failure());
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                    dispatch(alertActions.alert_error(error));
                    dispatch(alertActions.clear())
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(ride) { return { type: 'SUCCESS_DELETE_RIDE', ride } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function ViewUser(id) {
    return dispatch => {
        dispatch(request());
        userService.ViewUser(id)
            .then(
                Groups => {
                    dispatch(success(Groups));
                    dispatch(success1());
                    dispatch(alertActions.success('ViewUser successful'));
                },
                error => {
                    dispatch(failure());
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                    dispatch(alertActions.alert_error(error));
                    dispatch(alertActions.clear())
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(Groups) { return { type: 'VIEW_USER', Groups } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function ViewGroup(id) {
    return dispatch => {
        dispatch(request());
        userService.ViewGroup(id)
            .then(
                Users => {
                    dispatch(success(Users));
                    dispatch(success1());
                    dispatch(alertActions.success('ViewUser successful'));
                },
                error => {
                    dispatch(failure());
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                    dispatch(alertActions.alert_error(error));
                    dispatch(alertActions.clear())
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(Users) { return { type: 'VIEW_GROUP', Users } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function MessageGroup(groupMessage) {
    return dispatch => {
        dispatch(request());
        userService.MessageGroup(groupMessage)
            .then(
                groupMessage => {
                    dispatch(success());
                    dispatch(alertActions.success('Message sent'));
                    dispatch(modalActions.open_modal('Success','Message Sent'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function GetGraph(getData) {
    return dispatch => {
        dispatch(request());
        userService.GetGraph(getData)
            .then(
                getData => {
                    dispatch(success(getData));
                    dispatch(success1());
                    dispatch(alertActions.success('Graph data save'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(getData) { return { type: 'VIEW_GRAPH', getData } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function GetStatisic() {
    return dispatch => {
        dispatch(request());
        userService.GetStatisic()
            .then(
                statistics => {
                    dispatch(success1());
                    dispatch(success(statistics));
                    dispatch(alertActions.success('Get statsistics'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(statistics) { return { type: 'STATISTICS', statistics } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function GetSettings() {
    return dispatch => {
        dispatch(request());
        userService.GetSettings()
            .then(
                getSettings => {
                    dispatch(success1());
                    dispatch(success(getSettings));
                    dispatch(alertActions.success('Get settings'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success(getSettings) { return { type: 'GET_SETTINGS', getSettings } }
    function success1() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}
function SetSettings(settings) {
    return dispatch => {
        dispatch(request());
        userService.SetSettings(settings)
            .then(
                setSettings => {
                    dispatch(userActions.GetSettings());
                    dispatch(success());
                    dispatch(modalActions.open_modal('Success','Changed settings'));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.alert_error(error));
                    dispatch(modalActions.open_modal('Fail_popup','Bad request'));
                }
            );
    };

    function request() { return { type: 'REQUEST' } }
    function success() { return { type: 'SUCCESS' } }
    function failure() { return { type: 'FAILURE' } }
}