import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer} from 'recharts'
import { userActions } from '../../actions/index';


import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';


import moment from 'moment';


// http://recharts.org/en-US/examples/CardinalAreaChart
class Dashboard extends Component {

    constructor(props) {
        super(props);
        const startDate1 = new Date();
        const endDate1 = new Date();
        startDate1.setDate(startDate1.getDate() - 30 );
        const startDate = moment(startDate1).format('MM/DD/YYYY');
        const endDate = moment(endDate1).format('MM/DD/YYYY');
        this.state={
            dateLimit:{
                days:30
            },
            settings:{
                perGallon:'',
            },

            date:{
                emission:{
                    startDate:startDate,
                    endDate:endDate
                },
                saved:{
                    startDate:startDate,
                    endDate:endDate
                },
                cars:{
                    startDate:startDate,
                    endDate:endDate
                },
                gas:{
                    startDate:startDate,
                    endDate:endDate
                },
            },
            getData:{
                startDate:startDate,
                endDate:endDate,
                type:'all'
            }



        }
        this.handleEvent = this.handleEvent.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChangeInput = this.handleChangeInput.bind(this)

    }
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(userActions.GetGraph(this.state.getData))
        dispatch(userActions.GetStatisic())
        dispatch(userActions.GetSettings())
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.getSettings && nextProps.getSettings.settings.perGallon ){
            this.setState({
                settings:{
                    perGallon: nextProps.getSettings.settings.perGallon,
                }

            })
        }


    }
    handleEvent(event, picker) {
        const { dispatch } = this.props;
        const date_name = picker.element[0].children[0].id;
        const { date } = this.state;
        this.setState({
            getData: {
                    startDate: moment(picker.startDate).format('MM/DD/YYYY'),
                    endDate: moment(picker.endDate).format('MM/DD/YYYY'),
                    type: date_name

            }
        }, function () {
            dispatch(userActions.GetGraph(this.state.getData))
        });
        this.setState({
            date: {
                ...date,
                [date_name]: {
                    startDate: moment(picker.startDate).format('MM/DD/YYYY'),
                    endDate: moment(picker.endDate).format('MM/DD/YYYY')
                }
            }
        });

    }
    handleChangeInput(e) {
        const { name, value } = e.target;
        if (/^\d{0,6}(\.\d{0,4}){0,1}$/.test(value)) {
            this.setState({
                settings:{
                    [name]: value
                }

            });
        }

    }
    handleSubmit(event) {
        event.preventDefault();
        const { settings } = this.state;
        const { dispatch } = this.props;
        dispatch(userActions.SetSettings(settings));
    }

    render() {
        const { date } = this.state;
        const {GraphData, statistics} =this.props
        return (

            <div className="main_container">
                <div className="row">
                    <div className="cont2">
                        <div className="widget color1">
                            <h2>Statistics</h2>
                            <div className="txt_widget">
                                <div className="row">
                                    <div className="txt_lab width_95">Users:</div>
                                    <div >{statistics && statistics.usersCount?statistics.usersCount:''}</div>

                                </div>
                            </div>
                            <div className="txt_widget">
                                <div className="row">
                                    <div className="txt_lab width_95">Groups:</div>
                                    <div >{statistics && statistics.groupsCount?statistics.groupsCount:''}</div>

                                </div>
                            </div>
                            <div className="txt_widget">
                                <div className="row">
                                    <div className="txt_lab width_95">Rides:</div>
                                    <div >{statistics && statistics.ridesCount?statistics.ridesCount:''}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cont2 color2" id="id1">
                        <div className="widget color2">
                            <h2>Average price per gallon</h2>
                            <div className="txt_widget">
                                <div className="row">
                                    <form onSubmit={this.handleSubmit}  className="m_form">
                                        <label className="m_lbl" >
                                            <input type="text" className="form-control " name="perGallon"  value={this.state.settings.perGallon} onChange={this.handleChangeInput} />
                                        </label>
                                        <button className="btn btn-primary block  m-b">Save</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="cont2">
                        <div className="chart_head">
                            <div className="head_txt">Emission reduced</div>
                            <div>
                                <DateRangePicker startDate={date.emission.startDate} endDate={date.emission.endDate} onApply={this.handleEvent}  dateLimit={this.state.dateLimit} >
                                    <input  value={ `${date.emission.startDate} - ${date.emission.endDate}` || ''} onChange={()=>{}} id="emission" className="form-control"/>
                                </DateRangePicker>

                            </div>
                        </div>
                        <div className="chart_container">
                            {GraphData && GraphData.emission &&
                                <ResponsiveContainer>

                                    <AreaChart data={GraphData.emission}
                                               margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                        <CartesianGrid strokeDasharray="3 2"/>
                                        <XAxis dataKey="date"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Area type='monotone' dataKey='saved' stroke='#8884d8' fill='#8884d8' />
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        </div>
                    </div>

                    <div className="cont2">
                        <div className="chart_head">
                            <div className="head_txt">Saved parking spots</div>
                            <div>
                                <DateRangePicker startDate={date.saved.startDate} endDate={date.saved.endDate} onApply={this.handleEvent} dateLimit={this.state.dateLimit} >
                                    <input  value={ `${date.saved.startDate} - ${date.saved.endDate}` || ''} onChange={()=>{}} id="saved" className="form-control"/>
                                </DateRangePicker>

                            </div>
                        </div>
                        <div className="chart_container">
                            {GraphData && GraphData.saved &&
                                <ResponsiveContainer>
                                    <AreaChart data={GraphData.saved}
                                               margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="date"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Area type='monotone' dataKey='saved' stroke='#1ab394' fill='#1ab394'/>
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        </div>
                    </div>
                    <div className="cont2">
                        <div className="chart_head">
                            <div className="head_txt">Less cars off the road</div>
                            <div>
                                <DateRangePicker startDate={date.cars.startDate} endDate={date.cars.endDate} onApply={this.handleEvent} dateLimit={this.state.dateLimit} >
                                    <input  value={ `${date.cars.startDate} - ${date.cars.endDate}` || ''} onChange={()=>{}} id="cars" className="form-control"/>
                                </DateRangePicker>

                            </div>
                        </div>
                        <div className="chart_container">
                            {GraphData && GraphData.cars &&
                            <ResponsiveContainer>
                                <AreaChart data={GraphData.cars}
                                           margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="date"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Area type='monotone' dataKey='saved' stroke='#bd6262' fill='#bd6262'/>
                                </AreaChart>
                            </ResponsiveContainer>
                            }
                        </div>
                    </div>
                    <div className="cont2">
                        <div className="chart_head">
                            <div className="head_txt">Gas money saved</div>
                            <div>
                                <DateRangePicker startDate={date.gas.startDate} endDate={date.gas.endDate} onApply={this.handleEvent} dateLimit={this.state.dateLimit} >
                                    <input  value={ `${date.gas.startDate} - ${date.gas.endDate}` || ''} onChange={()=>{}} id="gas" className="form-control"/>
                                </DateRangePicker>

                            </div>
                        </div>
                        <div className="chart_container">
                            {GraphData && GraphData.gas &&
                                <ResponsiveContainer>
                                    <AreaChart data={GraphData.gas}
                                               margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="date"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Area type='monotone' dataKey='saved' stroke='#a0d895' fill='#a0d895'/>
                                    </AreaChart>
                                </ResponsiveContainer>
                            }
                        </div>
                    </div>








                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {GraphData} = state.GraphData
    const {statistics} = state.GetStatisic
    const {getSettings} = state.GetSettings
    return {
        GraphData,
        statistics,
        getSettings
    };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);
export { connectedDashboard as Dashboard };